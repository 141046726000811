import { Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Routes from '../../routes/Routes'
import ThemeProvider from '../../providers/ThemeProvider';
import { useExternalScript } from '../../hooks/useExternalScript';
import * as OneTrustConfigs from '../../configs/OneTrustConfgis';

function App() {
  const currentDomain = window.location.hostname?.split('.')[1];
  let state2 = "";
  let state = "";
  let src = "";
  let domainScript = "";
  const oneTrustScr = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  if (currentDomain) {
    var config = OneTrustConfigs.configs.find(x => x.BrandName == currentDomain.toLowerCase());
    if (config) {
      domainScript = config.DomainScript;
      src = config.Src;
    }
  }


  state2 = useExternalScript(src, domainScript);

  state = useExternalScript(oneTrustScr, domainScript);

  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={1}>
        {state !== "loading" && state2 !== "loading" && <Route path="/" component={Routes} />}
      </SnackbarProvider>
    </ThemeProvider>

  );
}

export default App;
