import React, { createContext, useContext, useState, setState, useEffect } from 'react';
import ThemeContext from '../context/ThemeContext';
import { useTheme } from '../theme/useTheme';
import { GlobalStyles } from "../theme/createGlobalStyles";
import { ThemeProvider as ThemeProviderSc } from 'styled-components';


const ThemeProvider = (props) => {
    const { theme, themeLoaded, getFonts } = useTheme();
    const [selectedTheme, setSelectedTheme] = useState(theme);
    const [brandConfig,setBrandConfig] = useState(null);
    useEffect(() => {
        setSelectedTheme(theme);
    }, [themeLoaded]);

    return (
        themeLoaded && (<ThemeContext.Provider value={{
            selectedTheme,
            setSelectedTheme,
            setBrandConfig,
            brandConfig
        }}>
            <ThemeProviderSc theme={selectedTheme}>
                <GlobalStyles />
                {props.children}
            </ThemeProviderSc>
        </ThemeContext.Provider>)
    )
};

export default ThemeProvider;
