import { themeConfigs } from '../constants'



export const getThemeConfig = name => {
    let themeConfig = themeConfigs.find((theme) => theme.name.toLocaleLowerCase('tr-TR') === name.toLocaleLowerCase('tr-TR'));
    if (!themeConfig) {
        let defaultTheme = themeConfigs.find((theme) => theme.name === "beko");
        return defaultTheme;
    }
    return themeConfig;
}


export const normalizeName = value => {

}