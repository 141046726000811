export const REACT_URL = "https://app-beko-extwarranty-prod.azurewebsites.net/";
export const SERVICE_URL = "https://app-producetreg-prod.azurewebsites.net/";
// export const REACT_URL = "http://localhost:3000/";
// export const SERVICE_URL = "https://localhost:44304/";
export const GET_ALL_COUNTRIES = SERVICE_URL + "api/country/v1/get/countries/";
export const SEND_VALIDATION_CODE = SERVICE_URL + "api/verify/v1/ext/generate-validation-code/";
export const VALIDATE_VALIDATION_CODE = SERVICE_URL + "api/verify/v1/code/";
export const GET_FORM_DYNAMIC_FIELDS = SERVICE_URL + "api/form/v1/fields/";
export const SEND_WARRANTY_FORM = SERVICE_URL + "api/extwarranty/v1/add";
export const GET_COUNTRY_LANGUAGES = SERVICE_URL + "api/language/v1/get/";
export const GET_PRODUCT_GROUPS = SERVICE_URL + "api/productgroup/v1/get/";
export const GET_TERMS = SERVICE_URL + "api/contract/v1/get/";
export const RESEND_CERTIFICATE = SERVICE_URL + "api/extwarranty/v1/certificate/resend";
export const GET_CONTENT_CONFIG = SERVICE_URL + "api/content/config";