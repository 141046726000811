

const requestHeader = {
    'Content-Type': 'application/json',
};




export function post(url, data) {
    return fetch(url, {
        method: "POST",
        headers: requestHeader,
        body: JSON.stringify(data),
    }).then(response => response);
}


export function get(url, header) {
    return fetch(url, {
        method: "GET",
        headers: header ? header : requestHeader,
    }).then(response => response.json());

}

export async function getAsync(url, header) {
    const response = await fetch(url, {
        method: "GET",
        headers: header ? header : requestHeader,
    });
    const data = await response.json();
    return data;
}