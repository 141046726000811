import React from 'react';
import ReactDOM from 'react-dom';
import App from './views/Root/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n';
import {setToLs} from './utils/storage';
import {getThemeConfig} from './theme/index';
import {themeConfigs as THEMES} from './constants'


const Index = () => {
  setToLs("all-themes",THEMES);

  return (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
  )
}

ReactDOM.render(
  <Index/>,
  document.getElementById('root')
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
