import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';

import LoadingScreen from '../components/LoadingScreen';
import ThemeContext from '../context/ThemeContext';
import ThemeConfigGuard from '../guards/ThemeConfigGuard';


const routesConfig = [
  {
    exact: true,
    path: '/',
    component: lazy(() => import('../views/RegistrationView')),
    guard: ThemeConfigGuard
  }
]


const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <ThemeContext.Consumer>
                {(context) => (
                  <Guard>
                    <Layout>
                      {route.routes
                        ? renderRoutes(route.routes)
                        : <Component themeConfig={context.selectedTheme} brandConfig={context.brandConfig} {...props} />}
                    </Layout>
                  </Guard>
                )}
              </ThemeContext.Consumer>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;