export const configs = [
    {
        BrandName: "grundig",
        Src: "https://cdn.cookielaw.org/consent/cf2458ef-361b-4d0a-805a-66c404719884/OtAutoBlock.js",
        DomainScript: "cf2458ef-361b-4d0a-805a-66c404719884"

    },
    {
        BrandName: "beko",
        Src: "https://cdn.cookielaw.org/consent/a107d389-7949-4cd7-882f-efe50f6bde0f/OtAutoBlock.js",
        DomainScript: "a107d389-7949-4cd7-882f-efe50f6bde0f"
    },
    {
        BrandName: "blomberginternational",
        Src: "https://cdn.cookielaw.org/consent/b15cb5b1-488d-4894-b0c2-ffb71312a3b7/OtAutoBlock.js",
        DomainScript: "b15cb5b1-488d-4894-b0c2-ffb71312a3b7"
    },
    {
        BrandName: "elektrabregenz",
        Src: "https://cdn.cookielaw.org/consent/f347e428-ffd3-4eef-9a8d-1569c22a736a/OtAutoBlock.js",
        DomainScript: "f347e428-ffd3-4eef-9a8d-1569c22a736a"
    }
]

