import GrundigLogo from '../assets/img/logo-grundig.png';
import BekoLogo from '../assets/img/logo-beko.jpg';
import BlombergLogo from '../assets/img/logo-blomberg.jpg';
import ElektraBregenzLogo from '../assets/img/logo-elektra_bregenz.png';

export const THEMES = {
  BEKO: "BEKO",
  GRUNDIG: "GRUNDIG",
  ARCTIC: "ARCTIC",
  BLOMBERG: "BLOMBERG",
  ELEKTRABREGENZ: "ELEKTRABREGENZ"
};

export const themeConfigs = [
  {
    id: THEMES.BEKO,
    name: THEMES.BEKO,
    colors: {
      clickHere: "#0083BE",
      clickHereLbl: "#fff",
      reminderButton: "#0083BE",
      inputTitle: "1px dashed #0096D7",
    },
    font: 'Encode Sans',
    logoPath: BekoLogo,
  },
  {
    id: THEMES.GRUNDIG,
    name: THEMES.GRUNDIG,
    colors: {
      clickHere: "#0A2033",
      clickHereLbl: "#fff",
      reminderButton: "#DBE6ED",
      inputTitle: "#0A2033",
    },
    font: "grundig-basics-regular,grundig-basics-semibold",
    logoPath: GrundigLogo,
  },
  {
    id: THEMES.BLOMBERG,
    name: THEMES.BLOMBERG,
    colors: {
      clickHere: "#b50027",
      clickHereLbl: "#fff",
      reminderButton: "#b50027",
      inputTitle: "#b50027",
    },
    font: 'Arial, Helvetica, sans-serif',
    logoPath: BlombergLogo,
  },
  {
    id: THEMES.ELEKTRABREGENZ,
    name: THEMES.ELEKTRABREGENZ,
    colors: {
      clickHere: "#b50027",
      clickHereLbl: "#fff",
      reminderButton: "#b50027",
      inputTitle: "#b50027",
    },
    font: 'Arial, Helvetica, sans-serif',
    logoPath: ElektraBregenzLogo,
  },
];

