import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
*,h1, h2, h3, h4, h5, h6, p, div, span {
  font-family: ${({ theme }) => theme.font}!important;
}


.btn-primary{
  background : ${({ theme }) => theme.colors.clickHere}!important;
}

.btn-primary:hover{
  color : ${({ theme }) => theme.colors.clickHereLbl}!important;
}

.MuiButton-root{
  background-color : ${({ theme }) => theme.colors.reminderButton}!important;
}

.input-title{
  color : ${({ theme }) => theme.colors.inputTitle}!important;
}

`;
