import React, { useContext, useEffect, useState } from 'react'
import * as Request from '../requests/Request';
import * as UrlConfig from '../routes/UrlConfig';
import ThemeContext from '../context/ThemeContext';
import { useTheme } from '../theme/useTheme';
import { getThemeConfig } from '../theme';
import GridLoader from 'react-spinners/GridLoader'

const ThemeConfigGuard = ({ children }) => {
    const { setMode } = useTheme();
    const { setSelectedTheme, setBrandConfig } = useContext(ThemeContext);
    const [pageLoading, setPageLoading] = useState(true);


    const getThemeConfigInit = async () => {
        var result = await Request
            .get(UrlConfig.GET_CONTENT_CONFIG)
            .then((response) => {
                return response;
            });
        return result;
    }

    useEffect(async () => {
        var result = await getThemeConfigInit();
        if (result.success) {
            var theme = getThemeConfig(result.data.name);
            setMode(theme);
            setSelectedTheme(theme);
            setBrandConfig(result.data);
        }
        setPageLoading(false);
    }, [])


    return pageLoading ? <div style={{ width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center" }}> <GridLoader color='#0088D3' loading={pageLoading} /></div> : children;
}

export default ThemeConfigGuard